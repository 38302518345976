<template>

    <div class="mb-3 card">
        <div>

            <!-- Table -->
            <Datatable v-show="showTable" ref="producerDatatable"
                       :api-endpoint="ENDPOINTS.DATATABLES.ERP.PRODUCERS"
                       :datatable-headers="datatableHeaders"
                       :excel-columns="excelColumns"
                       excel-file-name="Produzenten"
                       @editEntry="entryEdit"
                       @deleteEntry="entryDelete"
                       @deleteEntries="entriesDelete"
                       show-delete-buttons
                       show-edit-buttons
                       :permissionDelete="this.$store.getters['permissions/checkPermission']('manufacturer_delete')"
                       :permissionEdit="this.$store.getters['permissions/checkPermission']('manufacturer_edit')"
            />


        </div>

        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
            <vue-touch-keyboard id="onScreenKeyboard" class="internalWidthExpanded" :options="touchKeyboard.options"
                                :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                                :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                                :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
        </div>
    </div>
</template>

<script>
    import {ENDPOINTS} from '../../../config'
    import {Events} from "../../../plugins/events";
    import Datatable from "../../datatable/Datatable";
    import mixin from "../../../mixins/KeyboardMixIns";

    export default {
        components: {
            Datatable
        },
        mixins: [mixin],
        data() {
            return {
                ENDPOINTS,
                id: null,
                text: "",
                allergenAdd: "",
                loading: false,
                showTable: true,
                showCreate: false,
                showUpdate: false,
                tab: 0,
                // --- Datatable ---
                datatableHeaders: [
                    {
                        text: 'ID',
                        align: 'left',
                        value: 'id',
                        width: 80,
                        hide: true
                    },

                    {text: this.$t("erp.lang_ware_producer_name"), value: 'producerName',sort: "asc"},
                    {text: this.$t("customers.lang_customerID"), value: 'custID'},
                    {text: this.$t("generic.lang_street"), value: 'street'},
                    {text: this.$t("customers.lang_cust_zip"), value: 'zip'},
                    {text: this.$t("settings.lang_city"), value: 'city'},
                    {text: this.$t("generic.lang_country"), value: 'country'},
                ],
                excelColumns: [

                    {
                        label: "ID",
                        field: "id",
                    },
                    {
                        label: this.$t("erp.lang_ware_producer_name"),
                        field: "producerName",
                    },
                    {
                        label: this.$t("customers.lang_customerID"),
                        field: "custID",
                    },
                    {
                        label: this.$t("generic.lang_street"),
                        field: "street",
                    },
                    {
                        label: this.$t("customers.lang_cust_zip"),
                        field: "zip",
                    },
                    {
                        label: this.$t("settings.lang_city"),
                        field: "city",
                    },
                    {
                        label: this.$t("generic.lang_country"),
                        field: "country",
                    },
                ],
            }
        },

        watch: {
            tab: function () {
                if (this.tab === 1) {
                    this.showTable = true;
                    this.showUpdate = false;
                }
            }
        },

        methods: {

            /* DELETE A SINGLE PRODUCER OR MULTIPLE PRODUCERS
            * Awaits Object of producer IDs
            * */

            deleteData: function (idsToDelete = []) {
                this.$swal({
                    title: this.$t("erp.deleteProducerHeader"),
                    text: this.$t("erp.deleteProducerBody"),
                    icon: "warning",
                    cancelButtonText: this.$t('generic.lang_cancel'),
                    confirmButtonText: this.$t('generic.lang_delete'),
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
                            idsToDelete = [];
                            idsToDelete.push(parseInt(this.id));
                        }
                        this.axios.post(ENDPOINTS.ERP.PRODUCERS.DELETE, {
                            producerID: idsToDelete
                        }).then((res) => {
                            if (res.data.status === 'SUCCESS') {
                                Events.$emit("showSnackbar", {
                                    message: this.$t("erp.producerDeleted"),
                                    color: "success"
                                });

                                this.$refs.producerDatatable.getDataFromApi();
                                this.$refs.producerDatatable.resetSelectedRows();
                            } else {
                                Events.$emit("showSnackbar", {
                                    message: this.$t('generic.lang_errorOccurred'),
                                    color: "error"
                                });
                            }
                        });
                    },
                    allowOutsideClick: () => !this.$swal.isLoading()
                });
            },
            /*ROUTE TO THE PRODUCER EDIT PAGE
            * Producer ID is mandatory
            *  */
            entryEdit(entry) {
                this.id = entry.id;
                this.text = entry.name;

                this.$router.push({name: 'erp.storage.producer.edit', params: {id: parseInt(this.id)}})
            },
            /*DATATABLE DELETE METHOD */
            entryDelete(entry) {
                this.id = entry.id;
                this.deleteData();
            },
            /*DATATABLE DELETE METHOD */
            entriesDelete(entries) {
                this.deleteData(entries);
            }
        },
    }
</script>