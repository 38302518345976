<template>
    <div>
        <page-title :heading="$t('erp.lang_nav_manufacturer')"
                    :permissionAdd="this.$store.getters['permissions/checkPermission']('manufacturer_create')"
                    show-add-button :subheading="$t('erp.lang_nav_manufacturer')" :icon=icon></page-title>
        <div class="app-main__inner">
        <Producers></Producers>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../Layout/Components/PageTitle.vue";
    import Producers from "../../../components/erp/storage/Producers";

    export default {
        components: {
            PageTitle,
            Producers
        },

        data: () => ({
            icon: 'pe-7s-culture icon-gradient bg-tempting-azure',
        })
    }
</script>